import Enum from './enum'

/**
 * 処理ステータス
 */
class ProcessStatus extends Enum {
    static PENDING = 1;
    static PROCESSING = 2;
    static DONE = 3;
    static FAILED = 4;
    static ERROR = 9;

    static values() {
        return {
            [this.PENDING]: "処理待ち",
            [this.PROCESSING]: "処理中",
            [this.DONE]: "完了",
            [this.FAILED]: "失敗",
            [this.ERROR]: "エラー",
        }
    }
}

export default ProcessStatus;
